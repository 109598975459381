import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(148, 189, 239, 0.5);
  border-radius: 10px;
  max-width: 400px;
  width: 100%;
  padding: 1.25rem;
  gap: 30px;
  z-index: 20;

  @media (max-width: 410px) {
    width: 95%;
    padding: 0.5rem;
  }
`;

export const CommsTool = styled.div`
  margin: 0;
`;

export const ContainerAlert = styled.div`
  border-radius: 5px;
  background: #e5ff80;
  max-width: 360px;
  width: 100%;
  padding: 10px;
  gap: 9px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

export const TextAlert = styled.p`
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
`;

export const ContainerSteps = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
`;

export const ContainerStepIndividual = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  width: 100%;
`;

export const NumberStep = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2a7de1;
  color: #fff;
  border-radius: 50%;
  font-weight: 700;
  width: 40px !important;
  height: 40px;
`;

export const ContainerContentStep = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

export const TextStep = styled.p`
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.28px;
`;

export const ContainerMicrosoftStep = styled.a`
  background: #fff;
  padding: 16px 12px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
`;

export const LinkCopy = styled.p`
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: -0.24px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 220px;
  width: 85%;
  cursor: pointer;
`;

export const ContainerClipboard = styled.button`
  background: #94bdef;
  display: flex;
  align-items: center;
  border-radius: 5px;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  cursor: pointer;
  max-width: 290px;
  width: 100%;

  .image {
    background: transparent;
    outline: none;
    border: none;
    display: flex;
    align-self: flex-end;
  }

  img {
    cursor: pointer;
  }
`;

export const ContainerNeedHelp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Question = styled.a`
  text-align: center;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: -0.26px;
  cursor: default;
  color: #1a1e2b;
`;

export const LinkForHelp = styled(Question)`
  text-decoration: underline;
  cursor: pointer;
`;

export const ContainerImageTwoStep = styled('div')`
  img {
    width: 100%;
  }
`;
