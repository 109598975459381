import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 1em;

  h5 {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 25px;
    line-height: 35px;
    color: #1a1e2b;
  }
`;
