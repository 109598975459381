import styled from 'styled-components';

export const Container = styled.div<{ button: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
  background: #e9f1fb;
  box-shadow: 0px 10px 25px rgba(148, 189, 239, 0.5);
  border-radius: 10px;
  max-width: 550px;
  width: 100%;
  padding: 2em 0;
  cursor: ${({ button }) => (button ? 'default' : 'pointer')};
  z-index: 20;

  @media (max-width: 610px) {
    width: 90%;
    padding: 2em 1em;
  }
`;

export const Title = styled.h3`
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 19px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #1a1e2b;
  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 21px;
  }
`;

export const Subtitle = styled.p`
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #1a1e2b;

  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

export const ContainerCommsTools = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  max-width: 400px;
  z-index: 10;
`;

export const ContainerCommsTool = styled.button<{ styleDisabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  background: #ffffff;
  box-shadow: 0px 10px 25px rgba(148, 189, 239, 0.5);
  border-radius: 5px;
  width: 171px;
  height: 48px;
  cursor: ${({ styleDisabled }) => (styleDisabled ? 'default' : 'pointer')};
  opacity: ${({ styleDisabled }) => (styleDisabled ? '0.3' : '1')};
  transition: 0.3s;
  z-index: 12;

  @media (max-width: 429px) {
    width: 100%;
    height: 52px;
  }

  &:hover {
    transform: ${({ styleDisabled }) => (styleDisabled ? 'none' : 'scale(1.05)')};
  }
`;

export const ContainerCommsToolDisabled = styled(ContainerCommsTool)`
  opacity: 0.5;
  z-index: 10;
  cursor: default;

  &:hover {
    transform: none;
  }

  .project-card {
    margin: -119px 0 0 -24px;
    height: 150px;
    width: 150px;
    position: absolute;
    z-index: 12;
  }

  .achievement-label {
    opacity: 1 !important;
    display: grid;
    z-index: 15;
    position: absolute;
    width: 217px;
    padding: 0.1rem 0;
    box-sizing: border-box;
    background-color: #ccff00;
    transform: rotateZ(-45deg);
    top: 0;
    margin-top: 50px;
    left: 0;
    transform-origin: 150px 150px;
    text-align: justify;
    text-align-last: justify;
    clip-path: polygon(90px 0, calc(100% - 90px) 0, 100% 90px, 0 90px);
  }

  p {
    /* font-weight: 700; */
    color: #000;
    font-size: 12px;
    z-index: 94;

    /* margin: 0; */
  }

  div.achievement-label i {
    float: left;
    height: 100%;
    width: 90px;
    shape-outside: polygon(0 0, 90px 0%, 0 90px, 0% 90px);
  }

  div.achievement-label i + i {
    float: right;
    shape-outside: polygon(0 0, 90px 0, 90px 90px, 90px 90px);
  }
`;

export const ContainerTexts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

export const Security = styled.p`
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #b0b0b0;

  a {
    color: #b0b0b0;
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const ContainerInputForm = styled.form<{ disabledForm: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0.3em;
  width: 100%;
  justify-content: center;
  align-items: center;
  label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: #1a1e2b;
    align-self: flex-start;
  }

  input {
    background: #ffffff;
    width: 350px;
    height: 48px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    padding: 0 0 0 10px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: #1a1e2b;
    border: solid 1px ${({ disabledForm }) => (disabledForm ? '#ff0000' : 'none')};
    outline: none;
    @media (max-width: 420px) {
      width: 100%;
    }
  }
`;

export const ContainerInputLabel = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 430px) {
    width: 100%;
  }
`;
