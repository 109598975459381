import { FC } from 'react';

import { Container } from './styles';

export const InvalidEmail: FC = () => {
  return (
    <Container>
      <p>This invite is invalid</p>
    </Container>
  );
};
