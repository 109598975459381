import { FC } from 'react';

import { BoxCommsToolsWorker } from 'components/BoxCommsToolsWorker';

import { Container } from './styles';

export const SignUpWorker: FC = () => {
  console.log('SignUpWorker');

  return (
    <Container>
      <BoxCommsToolsWorker />
    </Container>
  );
};
