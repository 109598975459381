import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 30px;
    line-height: 35px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #1a1e2b;
  }
`;
