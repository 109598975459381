import { FC } from 'react';

import { Container } from './styles';

export const NotFound: FC = () => {
  return (
    <Container>
      <p>Page not found - 404</p>
    </Container>
  );
};
