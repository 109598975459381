import { FC } from 'react';

import { Activate } from 'components/Activate';

import { Container, Title } from './styles';

export const ActivatePage: FC = () => {
  return (
    <Container>
      <Title>Follow these steps to install the StartADAM Bot using Microsoft Teams</Title>
      <Activate  thirdStep/>
    </Container>
  );
};
