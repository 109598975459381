import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 3.125rem;
  width: 20.938rem;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.938rem;
`;

export const Checklist = styled.div`
  display: flex;
  gap: 0.625rem;
  padding-bottom: 0.3125rem;

  p {
    font-size: 0.9rem;
    color: #999999;
  }
`;

export const Title = styled.p`
  font-size: 1rem;
  font-weight: bold;
`;

export const SafeDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const IconGroup = styled.div`
  display: flex;
  gap: 1rem;
`;
