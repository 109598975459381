import { BoxUserExist } from 'components/BoxUserExist';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { validateEmail } from 'services/validateEmail';
import { useAppSelector } from 'store/hooks';
import { setCommsTool } from 'store/slices/commsTool';
import {
  selectLogoCommsTool,
  selectLogoCommsToolForBack,
} from 'services/selectCommsTool';
import { getAdamUser } from 'store/slices/getAdamUser/middleware';
import { commsTools } from 'config/listCommsTools';
import { IWhitelabelInvite, getWhitelabelInvite } from 'services/getWhitelabelInvite';
import {
  Container,
  ContainerCommsTool,
  ContainerCommsTools,
  ContainerInputForm,
  ContainerTexts,
  Title,
  ContainerInputLabel,
} from './styles';

const ADAM_II_URL = process.env.REACT_APP_ADAM_II_URL;

export const BoxCommsToolsWorker: FC = () => {
  const [dataPage, setDataPage] = useState<IWhitelabelInvite | null>(null);
  const [styleInInput, setStyleInInput] = useState(false);
  const [changeScreen, setChangeScreen] = useState(true);
  const [emailField, setEmailField] = useState('');
  const [verifyEmail, setVerifyEmail] = useState(false);

  const { inviteId } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useAppSelector((state) => state.adamUser);

  window.onpopstate = () => {
    location.reload();
  };

  const pathname = location.pathname;

  const redirect = (page = 'success') => {
    return navigate(`/${page}`);
  };

  const showMessage = () => {
    const friendlyName = dataPage?.properties?.friendlyName;
    const name = dataPage?.whitelabel?.name;
    const chatTool = friendlyName || name || '';
    if (
      dataPage?.properties?.friendlyName &&
      dataPage?.properties?.friendlyName.startsWith('livechat')
    ) {
      return 'Choose your preferred tool to transfer Live Chat.';
    }
    return `You have been invited to join ${chatTool}. Connect your chat tool to start collaborating with${' '}${
      dataPage?.properties?.managerName
    } & others.`;
  };

  useEffect(() => {
    const getData = async () => {
      const response = await getWhitelabelInvite(inviteId);
      if (response.status === 204) {
        navigate('/invalid-invite');
      }
      setDataPage(response.data);
    };
    getData();
  }, [inviteId]);

  useEffect(() => {
    const validateEmailField = () => {
      if (validateEmail(emailField)) {
        dispatch(getAdamUser({ email: emailField || dataPage?.userReference }));
        return setVerifyEmail(true);
      }
      return setVerifyEmail(false);
    };
    validateEmailField();
  }, [emailField, dispatch, dataPage]);

  useEffect(() => {
    if (validateEmail(emailField) && user?.userReference === emailField) {
      setChangeScreen(false);
      dispatch(setCommsTool(selectLogoCommsTool(user?.commsTool?.provider)));
    }
  }, [user, emailField, dispatch]);

  useEffect(() => {
    const validateUser = () => {
      if (user?.userReference) {
        return setChangeScreen(false);
      }
      return null;
    };
    validateUser();
  }, [user?.userReference]);

  const selectCommsTools = (commsTool) => {
    setStyleInInput(true);

    if (commsTool.title === 'MS Teams') {
      redirect(`activate?url=${location.href}`);
      return;
    }

    if (validateEmail(emailField) || dataPage?.userReference) {
      const state = window.btoa(
        JSON.stringify({
          path: pathname.replace('/', ''),
          provider: selectLogoCommsToolForBack(commsTool?.title),
          userReference: emailField || dataPage?.userReference,
        })
      );
      dispatch(setCommsTool(commsTool?.title));

      location.href = `${ADAM_II_URL}/chatIntegration?state=${state}`;
    }
  };

  return (
    <>
      {changeScreen ? (
        <Container>
          <ContainerTexts>
            <Title>
              {' '}
              {/* dataPage?.properties.typeOfInvite */}
              {showMessage()}
            </Title>
          </ContainerTexts>
          {dataPage?.userReference ? (
            <></>
          ) : (
            <ContainerInputForm disabledForm={!verifyEmail && styleInInput}>
              <ContainerInputLabel>
                <label htmlFor="email">Enter your email</label>
                <input
                  onChange={(e) => setEmailField(e.target.value)}
                  type="email"
                  id="email"
                />
              </ContainerInputLabel>
            </ContainerInputForm>
          )}
          <ContainerCommsTools>
            {commsTools.map((commsTool) => {
              return (
                <ContainerCommsTool
                  styleDisabled={commsTool?.disabled || (!verifyEmail && styleInInput)}
                  key={commsTool.title}
                  disabled={commsTool?.disabled || (!verifyEmail && styleInInput)}
                  onClick={() => selectCommsTools(commsTool)}>
                  <img src={commsTool.logo} alt={commsTool.title} />
                </ContainerCommsTool>
              );
            })}
          </ContainerCommsTools>
        </Container>
      ) : (
        <BoxUserExist />
      )}
    </>
  );
};
