import { FC } from 'react';
import { closeIcon } from 'assets/icons';
import {
  ButtonClose,
  ContainerCloseModal,
  ContainerContentModal,
  ContentModal,
  ContainerModal,
} from './styles';

interface IModal {
  children: JSX.Element;
  open: boolean;
  closeModal: (value) => void;
  maxWidth?: boolean;
}
export const Modal: FC<IModal> = ({ children, open, closeModal, maxWidth }) => {
  return (
    open && (
      <ContainerModal>
        <ContainerContentModal>
          <ContainerCloseModal>
            <ButtonClose onClick={closeModal}>
              <img src={closeIcon} alt="close modal" />
            </ButtonClose>
          </ContainerCloseModal>
          <ContentModal $maxWidth={maxWidth}>{children}</ContentModal>
        </ContainerContentModal>
      </ContainerModal>
    )
  );
};
Modal.defaultProps = {
  maxWidth: false,
};
