import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IDataToken, IDataTokenError } from './types';

interface IInitialStateDataToken {
  dataToken: IDataToken;
  errorToken: IDataTokenError;
}

const initialState: IInitialStateDataToken = {
  dataToken: null,
  errorToken: null,
};

const dataTokenSlice = createSlice({
  name: 'dataToken',
  initialState,
  reducers: {
    setDataToken: (state, action: PayloadAction<IDataToken>) => {
      state.dataToken = action.payload;
    },
    setErrorDataToken: (state, action: PayloadAction<any>) => {
      state.errorToken = action.payload;
    },
  },
});

export const { setDataToken, setErrorDataToken } = dataTokenSlice.actions;

export default dataTokenSlice.reducer;
