import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDataKey } from './types';

interface IInitialStateDataKey {
  dataKey: IDataKey;
}

const initialState: IInitialStateDataKey = {
  dataKey: null,
};

const dataKeySlice = createSlice({
  name: 'dataKey',
  initialState,
  reducers: {
    setDataKey: (state, action: PayloadAction<IDataKey>) => {
      state.dataKey = action.payload;
    },
  },
});

export const { setDataKey } = dataKeySlice.actions;

export default dataKeySlice.reducer;
