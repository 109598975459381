import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { useAppSelector } from 'store/hooks';
import { setCommsTool } from 'store/slices/commsTool';
import { getAdamUser } from 'store/slices/getAdamUser/middleware';
import { commsTools } from 'config/listCommsTools';

import { checkoutSession } from 'services/checkoutSession';
import { selectLogoCommsToolForBack } from 'services/selectCommsTool';
import {
  Container,
  ContainerCommsTool,
  ContainerCommsTools,
  ContainerTexts,
  Title,
} from './styles';

const ADAM_II_URL = process.env.REACT_APP_ADAM_II_URL;

export const BoxCommsToolsPaymentSuccess: FC = () => {
  const [userReference, setUserReference] = useState(null);

  const { checkoutSessionId } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useAppSelector((state) => state.adamUser);

  window.onpopstate = () => {
    location.reload();
  };

  const pathname = location.pathname;

  useEffect(() => {
    if (user?.commsTool?.workspace.split('::')[0] === 'undefined') {
      navigate(`/activate?url=${location.href}`);
    }
  }, [user, navigate]);

  useEffect(() => {
    if (userReference) {
      getAdamUser({ email: userReference });
    }
  }, [userReference]);

  useEffect(() => {
    if (checkoutSessionId) {
      const getUserReference = async () => {
        const response = await checkoutSession(checkoutSessionId);
        setUserReference(response);
      };
      getUserReference();
    }
  }, [checkoutSessionId]);

  const redirect = (page = 'success') => {
    return navigate(`/${page}`);
  };

  const selectCommsTools = (commsTool) => {
    if (commsTool.title === 'MS Teams') {
      redirect(`activate?url=${location.href}`);
      return;
    }

    const state = window.btoa(
      JSON.stringify({
        path: pathname.replace('/', ''),
        provider: selectLogoCommsToolForBack(commsTool?.title),
        userReference,
      })
    );

    dispatch(setCommsTool(commsTool?.title));

    location.href = `${ADAM_II_URL}/chatIntegration?state=${state}`;
  };

  return (
    <>
      <Container>
        <ContainerTexts>
          <Title>
            Ready to dive into your StartADAM trial? Just link up your chat tool to get
            started.
          </Title>
        </ContainerTexts>
        <ContainerCommsTools>
          {commsTools.map((commsTool) => (
            <ContainerCommsTool
              disabled={commsTool?.disabled}
              styleDisabled={commsTool?.disabled}
              key={commsTool.title}
              onClick={() => selectCommsTools(commsTool)}>
              <img src={commsTool.logo} alt={commsTool.title} />
            </ContainerCommsTool>
          ))}
        </ContainerCommsTools>
      </Container>
    </>
  );
};
