import { FC } from 'react';

import { logoAlink } from 'assets/images';
import { Container, WrapperImages, WrapperText } from './styles';

export const Footer: FC = () => {
  return (
    <Container>
      <WrapperImages>
        <img alt="startAdam logo" src={logoAlink} height={20} />
      </WrapperImages>
      <WrapperText>
        <p>
          <a href="https://startadam.com/" target="_blank" rel="noopener">
            © StartADAM
          </a>
          <span> (Speedlancer Inc). All Rights Reserved.</span>
          <a href="https://startadam.com/terms/">Terms of Service</a>{' '}
          <a href="https://startadam.com/privacy/">Privacy Policy</a>
        </p>
      </WrapperText>
    </Container>
  );
};
