import { toast } from 'react-toastify';

import { endpointsIntegration } from 'config/endpoints';
import { adamApiII } from './api';

export const checkoutSession = async (checkoutId: string) => {
  try {
    const response = await adamApiII.get(
      endpointsIntegration.checkoutUserReference(checkoutId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(
      'An unexpected error occurred. Please try again or contact our support at hello@startadam.com.'
    );
    return error.response;
  }
};
