export const endpointsIntegration = {
  getUser: (whitelabelId: string | undefined, email: string) =>
    whitelabelId
      ? `/user?userReference=${whitelabelId}:${encodeURIComponent(
          email
        )}&accountReference=pureConnect`
      : `/user?userReference=${encodeURIComponent(email)}&accountReference=pureConnect`,
  validateInviteToken: '/invite/validate/token',
  getToken: (shortUrl: string) => `/pure-connect/get-full-url/${shortUrl}`,
  joinChannel: '/pure-connect/join-channel',
  joinChannelUser: '/pure-connect/join-user-link-channel',
  getWhitelabel: (name: string) => `/orgConnectLink/linkName/${name}`,
  getChannelMap: (channelId: string, userId: string) =>
    `/pure-connect/get-channel-map?channelId=${channelId}&userId=${userId}`,
  welcomeWizard: '/pure-connect/join-welcome-wizard',
  checkoutUserReference: (checkoutSessiongId: string) =>
    `/billing/get-checkout-user-reference/${checkoutSessiongId}`,
  createSession: '/createSession',
  getWhitelabelPath: (path: string) => `/whitelabel/${path}`,
  getChatIntegration: (state: string) => `/chatIntegration?state=${state}`,
  getWhitelabelInvite: (inviteId?: string) => `/whitelabel/invite/${inviteId}`,
  getAlink: (uuid: string) => `/user/link?userId=${uuid}`,
  joinChannelInviteLink: '/pure-connect/join-invite-link',
  joinChannelALink: '/pure-connect/join-alink',
  checkJoinChannel: '/pure-connect/check-user-joined-link',
  createChannel: 'livechat/createChannel',
};
