import { FC } from 'react';

import { BoxCommsToolsPaymentSuccess } from 'components/BoxCommsToolsPaymentSuccess';

import { Container } from './styles';

export const SuccessPayment: FC = () => {
  return (
    <Container>
      <BoxCommsToolsPaymentSuccess />
    </Container>
  );
};
