import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAdamUser } from './types';

interface IInitialStateAdamUser {
  user: IAdamUser;
}

const initialState: IInitialStateAdamUser = {
  user: null,
};

const adamUserSlice = createSlice({
  name: 'adamUser',
  initialState,
  reducers: {
    setAdamUser: (state, action: PayloadAction<IAdamUser>) => {
      state.user = action.payload;
    },
  },
});

export const { setAdamUser } = adamUserSlice.actions;

export default adamUserSlice.reducer;
