import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

html, body {
    min-height: 100vh;
    padding: 0;
    margin: 0;
    font-size: 18px;
  }

body {
    font-family: 'Montserrat', sans-serif;
    background: radial-gradient(54.73% 74.56% at 95.28% 38.59%, #C6ECF5 0%, rgba(198, 236, 245, 0) 100%);
    display: flex;
    flex-direction: column;
  }

  footer {
    margin-top: auto;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
  }

  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    border: none;
    list-style: none;
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }

  button {
    border: none;
  }

  input, select {
    appearance: none;
  }

  #root {
    display: flex;
    flex-direction: column;
  }

  .loader {
    width: 28px;
    height: 28px;
    border: 5px solid #9fb548;
    border-bottom-color: #fff;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const ImageBackground = styled.img`
  position: fixed;
  z-index: 1;
  right: 0;
  top: 0;
  user-drag: none;
  user-select: none;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export default GlobalStyle;
