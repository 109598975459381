import { FC, useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery';

import { adamLogo2 } from 'assets/images';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { commsTools } from 'config/listCommsTools';
import animationData from 'assets/animations/lotties/success.json';
import { setCommsTool } from 'store/slices/commsTool';
import { selectLogoCommsTool } from 'services/selectCommsTool';
import { joinChannelALink } from 'services/joinChannelAlink';
import { getChannelMap } from 'services/getChannelMap';

import {
  ButtonChannel,
  Container,
  ContainerButtons,
  ContainerLoading,
  ContainerSpinner,
} from './styles';

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData,
};

export const BoxUserExistUser: FC = () => {
  const [awaitButtonChannel, setAwaitButtonChannel] = useState(false);
  const [showButtonJoinChannel, setShowButtonJoinChannel] = useState(false);

  const { commsTool } = useAppSelector((state) => state.commsTool);
  const { adamUser } = useAppSelector((state) => state);

  const isMobile = useMediaQuery('(max-width: 360px)');

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getCommsTool = commsTools.find((tool) => tool.title === commsTool);

  const pathname = location.pathname;

  useEffect(() => {
    dispatch(setCommsTool(selectLogoCommsTool(adamUser?.user?.commsTool?.provider)));
  }, [dispatch, adamUser]);

  useEffect(() => {
    const checkUser = async () => {
      const path = pathname.includes('getStarted') ? '' : pathname.replace('/', '');
      const response = await getChannelMap({ path, userId: adamUser.user.id });
      if (response?.status === 204) setShowButtonJoinChannel(true);
    };
    checkUser();
  }, [pathname, adamUser]);

  const joinChannelUser = async () => {
    setAwaitButtonChannel(true);
    try {
      const response = await joinChannelALink({
        userId: adamUser?.user?.id,
        path: pathname.replace('/', ''),
      });

      if (response.status === 200) {
        navigate(`/success/${adamUser?.user?.userReference}`);
      }
      return null;
    } catch (error) {
      return error;
    }
  };

  return (
    <Container>
      <ContainerLoading>
        <img src={getCommsTool?.logoSuccess} alt="logo" />
        <ContainerSpinner>
          <Lottie options={defaultOptions} width={isMobile ? 200 : 300} />
        </ContainerSpinner>
        <img src={adamLogo2} alt="logo" />
      </ContainerLoading>
      <ContainerButtons>
        {showButtonJoinChannel && (
          <ButtonChannel disabled={awaitButtonChannel} onClick={joinChannelUser}>
            {awaitButtonChannel ? <span className="loader" /> : 'Join Channel'}
          </ButtonChannel>
        )}
        <ButtonChannel
          style={{ background: '#FFFFFF' }}
          onClick={() => window.open('https://wa.me/19894673257', '_blank')}>
          Contact Support
        </ButtonChannel>
      </ContainerButtons>
    </Container>
  );
};
