import { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { useLocation, useNavigate } from 'react-router-dom';
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery';

import { adamLogo2 } from 'assets/images';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { commsTools } from 'config/listCommsTools';
import animationData from 'assets/animations/lotties/success.json';
import { setCommsTool } from 'store/slices/commsTool';
import { selectLogoCommsTool } from 'services/selectCommsTool';
import { joinChannelIniviteLink } from 'services/joinChannelInviteLink';
import { getChannelMap } from 'services/getChannelMap';

import {
  ButtonAccess,
  ButtonChannel,
  Container,
  ContainerLoading,
  ContainerSpinner,
} from './styles';

export const BoxUserExist = () => {
  const [awaitButtonChannel, setAwaitButtonChannel] = useState(false);
  const [showButtonJoinChannel, setShowButtonJoinChannel] = useState(false);

  const { commsTool } = useAppSelector((state) => state.commsTool);
  const { user } = useAppSelector((state) => state.adamUser);
  const isMobile = useMediaQuery('(max-width: 360px)');

  const { pathname } = useLocation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getCommsTool = commsTools.find((tool) => tool.title === commsTool);

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData,
  };

  useEffect(() => {
    const checkUser = async () => {
      const path = pathname.includes('getStarted') ? '' : pathname.replace('/', '');
      const response = await getChannelMap({ path, userId: user.id });
      if (response?.status === 204) setShowButtonJoinChannel(true);
    };
    checkUser();
  }, [pathname, user]);

  useEffect(() => {
    dispatch(setCommsTool(selectLogoCommsTool(user?.commsTool?.provider)));
  }, [user, dispatch]);

  const joinChannelUser = async () => {
    setAwaitButtonChannel(true);
    try {
      const response = await joinChannelIniviteLink({
        path: pathname.replace('/', ''),
        userId: user?.id,
      });

      if (response.status === 200) {
        navigate(`/success/${user?.userReference}`);
      }
      return null;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  return (
    <Container>
      <ContainerLoading>
        <img src={getCommsTool?.logoSuccess} alt="logo" />
        <ContainerSpinner>
          <Lottie options={defaultOptions} width={isMobile ? 200 : 300} />
        </ContainerSpinner>
        <img src={adamLogo2} alt="logo" />
      </ContainerLoading>

      {showButtonJoinChannel && (
        <ButtonChannel disabled={awaitButtonChannel} onClick={joinChannelUser}>
          {awaitButtonChannel ? <span className="loader" /> : 'Join Channel'}
        </ButtonChannel>
      )}

      <ButtonAccess onClick={() => window.open('https://wa.me/19894673257', '_blank')}>
        Contact Support
      </ButtonAccess>
    </Container>
  );
};
