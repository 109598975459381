import { FC } from 'react';

import { Container } from './styles';

export const InvalidToken: FC = () => {
  return (
    <Container>
      <h5>This token is invalid</h5>
      <p>Expired token</p>
    </Container>
  );
};
