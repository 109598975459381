import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ICommsToolSlice {
  commsTool: string;
}

const initialState: ICommsToolSlice = {
  commsTool: '',
};

const commsToolSlice = createSlice({
  name: 'commsTool',
  initialState,
  reducers: {
    setCommsTool: (state, action: PayloadAction<string>) => {
      state.commsTool = action.payload;
    },
  },
});

export const { setCommsTool } = commsToolSlice.actions;

export default commsToolSlice.reducer;
