import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
`;

export const TitleUserExist = styled.p`
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 19px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #1a1e2b;
  max-width: 350px;
`;
