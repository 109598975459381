import styled from 'styled-components';

export const Container = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3.125rem;
  width: 100%;
  z-index: 20;
  margin-top: auto;
  position: relative;
  bottom: 0;
`;

export const WrapperImages = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;

  img {
    width: 7.813rem;
  }

  p {
    color: #c6c6c6;
  }
`;

export const WrapperText = styled.div`
  text-align: center;
  margin-top: 0.625rem;
  max-width: 14.688rem;

  p {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 0.625rem;
    line-height: 0.938rem;
    color: #c6c6c6;
    margin: 0.2rem 0;
  }

  a {
    text-decoration: underline;
    color: #c6c6c6;
    white-space: nowrap;
  }

  @media (max-width: 25.625rem) {
    p {
      flex-direction: column;
      align-items: center;
    }

    a {
      margin-top: 0.188rem;
    }
  }
`;
