import { useCallback } from 'react';
import { toast } from 'react-toastify';

export const useClipboard = () => {
  const copyToClipboard = useCallback(async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success('Link copied to clipboard');
    } catch (err) {
      console.log(err);
      toast.error('Error copying link');
    }
  }, []);
  return copyToClipboard;
};
