import { FC, useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { commsTools } from 'config/listCommsTools';
import animationData from 'assets/animations/lotties/success.json';
import { setCommsTool } from 'store/slices/commsTool';
import { getAlink } from 'services/getAlink';
import { selectLogoCommsTool } from 'services/selectCommsTool';
import {
  iconArrowRight,
  iconCopy,
  iconNumberOneBlue,
  iconNumberTwoBlue,
} from 'assets/icons';
import { IAdamUser } from 'store/slices/getAdamUser/types';
import {
  Alink,
  BoxSteps,
  Button,
  Container,
  ContainerLoading,
  ContainerSpinner,
  LoadingSpinner,
  StepContainer,
  Title,
} from './styles';

interface IBoxSuccess {
  user: IAdamUser;
  isJoinLink: boolean;
}

export const BoxSuccess: FC<IBoxSuccess> = ({ user, isJoinLink }) => {
  const [showAnimation, setShowAnimation] = useState(false);
  const [aLink, setAlink] = useState('');
  const [loading, setLoading] = useState(true);
  const { commsTool } = useAppSelector((state) => state.commsTool);
  const { whitelabel } = useAppSelector((state) => state.whitelabel);
  console.log(whitelabel);

  const dispatch = useAppDispatch();

  const href = process.env.REACT_APP_MY_A_LINK;

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(aLink);
      toast.success('Copied successfully');
    } catch (err) {
      console.error('Failed to copy:', err);
      toast.error('Failed to copy');
    }
  };

  useEffect(() => {
    const getUserInfo = async () => {
      setLoading(true);
      if (user) {
        try {
          await dispatch(setCommsTool(selectLogoCommsTool(user?.commsTool?.provider)));
          const response = await getAlink(user?.id);
          if (response.status === 200 && response.data.link) {
            setAlink(response.data.link);
          }
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      }
    };
    getUserInfo();
  }, [user, dispatch]);

  useEffect(() => {
    const id = setTimeout(() => {
      setShowAnimation(true);
    }, 1500);

    return () => clearTimeout(id);
  }, []);

  const isMobile = useMediaQuery('(max-width: 360px)');

  const getCommsTool = commsTools.find((tool) => tool.title === commsTool);

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData,
  };

  if (loading) {
    return (
      <Container>
        <LoadingSpinner />
      </Container>
    );
  }

  if (isJoinLink) {
    return (
      <Container>
        <ContainerLoading>
          <img src={getCommsTool?.logoSuccess} alt="logo" />
          <ContainerSpinner>
            {showAnimation && (
              <Lottie options={defaultOptions} width={isMobile ? 200 : 335} />
            )}
          </ContainerSpinner>
        </ContainerLoading>
        <Title>
          You have successfully joined a chat with{' '}
          {whitelabel?.userName || whitelabel?.channelName || whitelabel?.companyName} in
          your {commsTool}
        </Title>
        <Title>What&apos;s next?</Title>
        <BoxSteps>
          <p>
            Just go to your {commsTool}, locate the new conversation and start chatting.
          </p>
        </BoxSteps>
      </Container>
    );
  }

  return (
    <Container>
      <ContainerLoading>
        <img src={getCommsTool?.logoSuccess} alt="logo" />
        <ContainerSpinner>
          {showAnimation && (
            <Lottie options={defaultOptions} width={isMobile ? 200 : 335} />
          )}
        </ContainerSpinner>
      </ContainerLoading>
      <Title>Success! Access your {commsTool} and start collaborating!</Title>
      <Title>What&apos;s next?</Title>
      <BoxSteps>
        <StepContainer>
          <img src={iconNumberOneBlue} alt="icon number one" />
          <p>
            Just share your a.link so your contacts can chat with you from their favorite
            tool
          </p>
        </StepContainer>
        <Alink>
          <p>{aLink}</p>
          <button type="button" onClick={handleCopy}>
            <img height={24} alt="icon copy" src={iconCopy} />
          </button>
        </Alink>
        <span>
          Need to customize or set up branding? Access the Client Portal - it&apos;s free!
        </span>
      </BoxSteps>
      <BoxSteps>
        <StepContainer>
          <img src={iconNumberTwoBlue} alt="icon number one" />
          <p>Manage your account and more, using our Business Portal</p>
        </StepContainer>

        <Button href={href} target="_blank">
          <p>Access my.a.link</p>
          <img src={iconArrowRight} alt="arrow right" height={8} />
        </Button>
      </BoxSteps>
    </Container>
  );
};
