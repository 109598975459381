import { endpointsIntegration } from 'config/endpoints';
import { adamApiII } from './api';

export interface IWhitelabelInvite {
  accepted: boolean;
  properties: {
    boardName: string;
    channelId: string;
    friendlyName: string;
    managerName: string;
    typeOfInvite: string;
  };
  userReference: string;
  whitelabel?: {
    channel: boolean;
    description?: string;
    name: string;
    photo: string;
    provider: string;
  };
}

export const getWhitelabelInvite = async (inviteId: string) => {
  try {
    const response = await adamApiII.get<IWhitelabelInvite>(
      endpointsIntegration.getWhitelabelInvite(inviteId)
    );
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};
