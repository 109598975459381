import {
  AnyAction,
  combineReducers,
  configureStore,
  Store,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import type {} from 'redux-thunk/extend-redux';

import commsToolReducer from './slices/commsTool';
import dataTokenReducer from './slices/getDataToken';
import getAdamUserReducer from './slices/getAdamUser';
import getDataKeyReducer from './slices/getDataKey';
import getWhitelabel from './slices/getWhitelabel';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['dataToken', 'dataKey', 'adamUser'],
};

const reducer = {
  commsTool: commsToolReducer,
  dataToken: dataTokenReducer,
  adamUser: getAdamUserReducer,
  dataKey: getDataKeyReducer,
  whitelabel: getWhitelabel,
};

const rootReducer = combineReducers(reducer);

const persistedReducer = persistReducer(persistConfig, rootReducer);

export type RootState = ReturnType<typeof rootReducer>;

export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;

export type AppStore = Omit<Store<RootState, AnyAction>, 'dispatch'> & {
  dispatch: AppThunkDispatch;
};
export const store: AppStore = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV === 'development',
});

const persistor = persistStore(store);

export default persistor;
export type AppDispatch = AppThunkDispatch;
