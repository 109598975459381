import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import { getAdamUser } from 'store/slices/getAdamUser/middleware';
import { setCommsTool } from 'store/slices/commsTool';
import { selectLogoCommsTool } from 'services/selectCommsTool';
import { clipboardIcon, exclamationIcon, microsoftIcon } from 'assets/icons';
import { microsoftInstructions, microsoftStore } from 'assets/images';

import { useClipboard } from 'hooks/useClipboard';
import {
  Container,
  ContainerAlert,
  ContainerClipboard,
  ContainerContentStep,
  ContainerImageTwoStep,
  ContainerMicrosoftStep,
  ContainerNeedHelp,
  ContainerStepIndividual,
  ContainerSteps,
  LinkCopy,
  LinkForHelp,
  NumberStep,
  Question,
  TextAlert,
  TextStep,
} from './styles';

export const Activate: FC<{ thirdStep: boolean }> = ({ thirdStep }) => {
  const dispatch = useAppDispatch();

  const { user } = useAppSelector((state) => state.adamUser);

  const copyToClipboard = useClipboard();

  const { userReference } = useParams();

  const urlParams = new URLSearchParams(window.location.search);
  const manager = urlParams.get('manager');
  const url = urlParams.get('url');

  useEffect(() => {
    if (thirdStep) {
      const getUser = async () => {
        dispatch(getAdamUser({ email: userReference }));
      };
      getUser();
    }
  }, [userReference, dispatch]);

  useEffect(() => {
    if (user?.commsTool?.provider && thirdStep)
      dispatch(setCommsTool(selectLogoCommsTool(user?.commsTool?.provider)));
  }, [user, dispatch]);

  return (
    <Container>
      <ContainerAlert>
        <img src={exclamationIcon} alt="icon alert info" />
        <TextAlert>
          Please keep this window open until the process is complete. Thank you!
        </TextAlert>
      </ContainerAlert>
      <ContainerSteps>
        <ContainerStepIndividual>
          <div>
            <NumberStep>1</NumberStep>
          </div>
          <ContainerContentStep>
            <TextStep>
              Click the button below and install the StartADAM Bot into a team.
            </TextStep>
            <ContainerMicrosoftStep
              href="https://appsource.microsoft.com/en-us/product/office/wa200005304?tab=overview"
              target="_blank">
              <img src={microsoftIcon} alt="microsoft icon" />
              <img src={microsoftStore} alt="microsoft store" />
            </ContainerMicrosoftStep>
          </ContainerContentStep>
        </ContainerStepIndividual>
        <ContainerStepIndividual>
          <div>
            <NumberStep>2</NumberStep>
          </div>
          <ContainerContentStep>
            <TextStep>
              Go to the private Chat with StartADAM and click on the authorization link.
            </TextStep>
            <ContainerImageTwoStep>
              <img src={microsoftInstructions} alt="microsoft store" />
            </ContainerImageTwoStep>
          </ContainerContentStep>
        </ContainerStepIndividual>
        {thirdStep && (
          <ContainerStepIndividual>
            <div>
              <NumberStep>3</NumberStep>
            </div>
            {!(manager === 'true') ? (
              <ContainerContentStep>
                <TextStep>
                  In the Chat, click ‘Join a group’ and paste the link below.
                </TextStep>
                <ContainerClipboard
                  onClick={() => copyToClipboard(url)}
                  className="area-copy">
                  <LinkCopy>{url}</LinkCopy>
                  <img src={clipboardIcon} alt="copy link" />
                </ContainerClipboard>
              </ContainerContentStep>
            ) : (
              <ContainerContentStep>
                <TextStep>
                  In the Chat, click ‘Create a group’, and follow the instructions to
                  create your first cross-platform group.
                </TextStep>
              </ContainerContentStep>
            )}
          </ContainerStepIndividual>
        )}
      </ContainerSteps>
      <ContainerNeedHelp>
        <Question>Need help?</Question>
        <LinkForHelp
          href="https://calendly.com/d/x5b-36c-cmm/hello-startadam"
          target="_blank">
          Click here to get an onboarding session.
        </LinkForHelp>
      </ContainerNeedHelp>
    </Container>
  );
};
