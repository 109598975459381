import { endpointsIntegration } from 'config/endpoints';
import { adamApiII } from './api';

export const getAlink = async (uuid: string) => {
  try {
    const response = await adamApiII.get(endpointsIntegration.getAlink(uuid));
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};
