import { createAsyncThunk } from '@reduxjs/toolkit';
import { endpointsIntegration } from 'config/endpoints';
import { adamApiII } from 'services/api';
import { setWhitelabel } from '.';
import { IDataWhitelabel } from './types';

export const getWhitelabel = createAsyncThunk(
  'getWhitelabel/get',
  async (name: string, thunkAPI) => {
    try {
      const response = await adamApiII.get<IDataWhitelabel>(
        endpointsIntegration.getWhitelabelPath(name)
      );
      thunkAPI.dispatch(setWhitelabel(response.data));
      if (response.status === 204) {
        thunkAPI.dispatch(setWhitelabel('invalid' as any));
        return response.data;
      }
      return response.data;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
);
