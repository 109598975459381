import { endpointsIntegration } from 'config/endpoints';
import { adamApiII } from './api';

export const joinChannelIniviteLink = async (payload: {
  userId: string;
  path: string;
}) => {
  try {
    const response = await adamApiII.post(
      endpointsIntegration.joinChannelInviteLink,
      payload
    );
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};
