import styled from 'styled-components';

export const Breadcrumbs = styled.div`
  border: solid 1px #2a7de1;
  border-radius: 31.25rem;
  background-color: #c9def7;

  p {
    font-size: 0.813rem;
    color: #2a7de1;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 3em;
`;
