import { endpointsIntegration } from 'config/endpoints';
import { IDataWhitelabel } from 'store/slices/getWhitelabel/types';
import { adamApiII } from './api';

export const getWhitelabelPath = async (path: string) => {
  try {
    const response = await adamApiII.get<IDataWhitelabel>(
      endpointsIntegration.getWhitelabelPath(path)
    );
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};
