import { FC } from 'react';
import { iconArrow } from 'assets/icons';
import { Breadcrumbs, Container } from './styles';

interface IBreadcrumb {
  pages: Array<string>;
  active: string;
}
export const Breadcrumb: FC<IBreadcrumb> = ({ pages, active }) => {
  return (
    <Container>
      {pages.map((item, index) => (
        <>
          <Breadcrumbs $active={item === active} key={item}>
            <p>
              {index + 1}. {item}
            </p>
          </Breadcrumbs>
          {index !== pages.length - 1 && <img src={iconArrow} alt="arrow" height={24} />}
        </>
      ))}
    </Container>
  );
};
