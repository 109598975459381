import { FC, useEffect, useState } from 'react';
import { commsTools } from 'config/listCommsTools';
import { Activate } from 'components/Activate';
import { Modal } from 'components/Modal';
import {
  ContainerCommsTools,
  ContainerTexts,
  Title,
  LabelCommsTools,
  BoxCommsTools,
  ContainerCommsToolButton,
} from '../BoxCommsToolsWorkerCompanyAndUser/styles';

const ADAM_II_URL = process.env.REACT_APP_ADAM_II_URL;

export const ConnectChatTool: FC<{
  email: string;
  name: string;
}> = ({ email, name }) => {
  const [openMicrosoftModal, setOpenMicrosoftModal] = useState(false);

  const connectCommsTool = async (provider: string) => {
    if (provider === 'msteams') {
      setOpenMicrosoftModal(true);
      return;
    }
    const code = window.btoa(
      JSON.stringify({
        path: '',
        provider,
        userReference: email,
      })
    );
    const redirectToChatTool = async (code) => {
      try {
        location.href = `${ADAM_II_URL}/chatIntegration?state=${code}`;
      } catch (err) {
        console.error(err);
      }
    };

    redirectToChatTool(code);
  };

  useEffect(() => {
    if (openMicrosoftModal) {
      console.log('Modal Opened, Name:', name);
    }
  }, [openMicrosoftModal, name]);

  return (
    <>
      <ContainerTexts>
        <Title>
          Oops! {name}, it looks like you need to reconnect your chat tool before
          proceeding
        </Title>
      </ContainerTexts>
      <BoxCommsTools>
        <LabelCommsTools>Join with your favorite chat tool</LabelCommsTools>
        <ContainerCommsTools>
          {commsTools.map((commsTool) => {
            return (
              <ContainerCommsToolButton
                key={commsTool.title}
                onClick={() => connectCommsTool(commsTool.name)}>
                <img src={commsTool.logo} alt={commsTool.title} />
              </ContainerCommsToolButton>
            );
          })}
        </ContainerCommsTools>
      </BoxCommsTools>

      {openMicrosoftModal && (
        <Modal
          open={openMicrosoftModal}
          closeModal={() => setOpenMicrosoftModal(false)}
          maxWidth>
          <Activate thirdStep={false} />
        </Modal>
      )}
    </>
  );
};
