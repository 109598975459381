import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Container, ContainerClipboard, Subtitle, Text, Wrapper } from './styles';

export const Error: FC = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const userEmail = queryParams.get('userEmail');
  const link = queryParams.get('link');
  const messageError = queryParams.get('messageError');

  return (
    <Container>
      <h5>Error connecting chat tool</h5>

      <Subtitle>{messageError}</Subtitle>

      <Wrapper>
        <Text>You can try connecting again on the same link:</Text>
        <ContainerClipboard onClick={() => navigate(`${link}?userEmail=${userEmail}`)}>
          https://a.link{link}
        </ContainerClipboard>
      </Wrapper>
    </Container>
  );
};
