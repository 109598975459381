import { BrowserRouter, Routes, Route } from 'react-router-dom';
import * as Page from './pages';

const location = window.location;

const URL = location.host.includes('link')
  ? process.env.REACT_APP_URL_A_LINK
  : process.env.REACT_APP_URL;

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/invite/:inviteId" element={<Page.SignUpWorker />} />
        <Route path="/success/:userReference" element={<Page.SuccessPage />} />
        <Route path="/activate/:userReference" element={<Page.ActivatePage />} />
        <Route
          path="/successPayment/:checkoutSessionId"
          element={<Page.SuccessPayment />}
        />
        <Route path="/success" element={<Page.SuccessPage />} />
        <Route path="/activate" element={<Page.ActivatePage />} />
        <Route path="/invalid-token" element={<Page.InvalidToken />} />
        <Route path="/invalid-invite" element={<Page.InvalidEmail />} />
        {URL.includes('link') ? (
          <Route
            path="/:company/:userName"
            element={<Page.SignUpWorkerCompanyAndUser />}
          />
        ) : null}
        {URL.includes('link') ? (
          <Route
            path="/:company/:groupname"
            element={<Page.SignUpWorkerCompanyAndUser />}
          />
        ) : null}
        {URL.includes('link') ? (
          <Route path="/group/:groupname" element={<Page.SignUpWorkerCompanyAndUser />} />
        ) : null}
        {URL.includes('link') ? (
          <Route path="/:userName" element={<Page.SignUpWorkerCompanyAndUser />} />
        ) : null}
        {URL.includes('link') ? (
          <Route
            path="/getstarted/:email"
            element={<Page.SignUpWorkerCompanyAndUser />}
          />
        ) : null}
        {URL.includes('link') ? (
          <Route path="/:userName/test" element={<Page.SignUpTest />} />
        ) : null}
        {URL.includes('link') ? (
          <Route path="/" element={<Page.SignUpWorkerCompanyAndUser />} />
        ) : null}
        {!URL.includes('link') ? (
          <Route path="/getstarted/:email" element={<Page.SignUpManager />} />
        ) : null}
        {!URL.includes('link') ? (
          <Route path="/" element={<Page.SignUpManager />} />
        ) : null}
        <Route path="*" element={<Page.NotFound />} />
        <Route path="error" element={<Page.Error />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
