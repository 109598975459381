export const selectLogoCommsTool = (commsTool: string) => {
  switch (commsTool) {
    case 'whatsapp':
      return 'WhatsApp';
    case 'discord':
      return 'Discord';
    case 'telegram':
      return 'Telegram';
    case 'slack':
      return 'Slack';
    case 'msteams':
      return 'MS Teams';
    case 'sms':
      return 'SMS';
    default:
      return null;
  }
};

export const selectLogoCommsToolForBack = (commsTool: string) => {
  switch (commsTool) {
    case 'WhatsApp':
      return 'whatsapp';
    case 'Discord':
      return 'discord';
    case 'Telegram':
      return 'telegram';
    case 'Slack':
      return 'slack';
    case 'MS Teams':
      return 'msteams';
    case 'SMS':
      return 'sms';
    default:
      return null;
  }
};
