import { iconJira, iconTrello } from 'assets/icons';
import { logoJira, logoTrello } from 'assets/images';
import { FC } from 'react';
import {
  Button,
  Container,
  ContainerButtons,
  ContainerSelectButtons,
  ContainerTexts,
  InfoSelectTool,
  SelectTool,
} from './styles';

interface IBoardTools {
  showSelectBoardTool: boolean;
  setShowSelectBoardTool: (showButtons: boolean) => void;
}

export const BoardTools: FC<IBoardTools> = ({
  showSelectBoardTool,
  setShowSelectBoardTool,
}) => {
  const redirectTrello = () => {
    window.open('https://trello.com/power-ups/62c5a29aec2d813fcce1687f');
  };

  return (
    <Container
      showButtons={showSelectBoardTool}
      onClick={() => setShowSelectBoardTool(true)}>
      <ContainerTexts>
        <SelectTool>
          <img style={{ marginRight: '5px' }} src={iconTrello} alt="icon trello" />
          Trello or
          <img
            style={{ marginRight: '5px', marginLeft: '5px' }}
            src={iconJira}
            alt="icon jira"
          />
          Jira user?
        </SelectTool>
        <SelectTool>Try our management solutions for free.</SelectTool>
      </ContainerTexts>
      {showSelectBoardTool && (
        <ContainerSelectButtons>
          <InfoSelectTool>
            Go to your project management tool and install our app.
          </InfoSelectTool>
          <ContainerButtons>
            <Button onClick={redirectTrello}>
              <img src={logoTrello} alt="logo jira" />
            </Button>
            <Button disabled disableStyle>
              <img src={logoJira} alt="logo jira" />
            </Button>
          </ContainerButtons>
        </ContainerSelectButtons>
      )}
    </Container>
  );
};
