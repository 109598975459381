import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { validateEmail } from 'services/validateEmail';
import { useAppSelector } from 'store/hooks';
import { selectLogoCommsToolForBack } from 'services/selectCommsTool';
import { getAdamUser } from 'store/slices/getAdamUser/middleware';
import { commsTools, providers } from 'config/listCommsTools';
import { BoxUserExistUser } from 'components/BoxUserExistUser';
import { getChannelMap } from 'services/getChannelMap';
import { IDataWhitelabel } from 'store/slices/getWhitelabel/types';
import { ConnectChatTool } from 'components/ConnectChatTool';
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter';
import {
  Container,
  ContainerCommsTool,
  ContainerCommsTools,
  ContainerInputForm,
  ContainerTexts,
  Subtitle,
  Title,
  ContainerInputLabel,
  LabelCommsTools,
  BoxCommsTools,
  ContainerCommsToolButton,
  BackgroundImageWhitelabel,
  FooterNote,
} from './styles';

const ADAM_II_URL = process.env.REACT_APP_ADAM_II_URL;

export const BoxCommsToolsWorkerCompanyAndUser: FC<{
  userEmail?: string;
  dataPage: IDataWhitelabel;
  pathname: string;
  nameInPath: string;
  isGetStarted: boolean;
}> = ({ userEmail, dataPage, pathname, nameInPath, isGetStarted }) => {
  const [styleInInput, setStyleInInput] = useState(false);
  const [emailField, setEmailField] = useState('');
  const [verifyEmail, setVeirfyEmail] = useState(false);
  const [userExist, setUserExist] = useState(false);
  const [title, setTitle] = useState('');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useAppSelector((state) => state.adamUser);
  const { userName, company } = useParams();

  const name = dataPage?.userName || dataPage?.channelName || dataPage?.companyName;

  const redirect = useCallback(
    (page = 'success') => {
      return navigate(`/${page}`);
    },
    [navigate]
  );

  useEffect(() => {

    const validateEmailField = async () => {
      if (validateEmail(emailField)) {
        if (dataPage?.whitelabelId) {
          await dispatch(
            getAdamUser({ whitelabelId: dataPage?.whitelabelId, email: emailField })
          );
          return setVeirfyEmail(true);
        }
        await dispatch(getAdamUser({ email: emailField }));
        return setVeirfyEmail(true);
      }
      return setVeirfyEmail(false);
    };
    validateEmailField();
  }, [emailField, dispatch, dataPage?.whitelabelId]);

  useEffect(() => {
    if (user?.id) {
      setUserExist(true);
    }
  }, [user]);

  useEffect(() => {
    setEmailField(userEmail ?? '');
  }, [userEmail]);

  const selectCommsTools = async (commsTool: any) => {
    setStyleInInput(true);

    if (commsTool.title === 'MS Teams') {
      redirect(
        `activate?url=${location.href}${
          !userName && !company ? '&manager=true' : '&manager=false'
        }`
      );
      return;
    }

    const state = window.btoa(
      JSON.stringify({
        path: pathname.replace('/', ''),
        provider: selectLogoCommsToolForBack(commsTool?.title),
        userReference: emailField,
      })
    );
    if (validateEmail(emailField) && state) {
      location.href = `${ADAM_II_URL}/chatIntegration?state=${state}`;
    }
  };

  const subtitleMessage = () => {
    const defaultText =
      'Join StartADAM with your preferred chat app and get your branded link where your contacts can reach you easily.';
    const groupLinkText = `Join group ${dataPage?.channelName} using your preferred app`;
    const messages = {
      companyUser: `Join a chat with ${dataPage?.userName} (${dataPage?.companyName}) using your preferred app`,
      company: `Join a chat with ${dataPage?.companyName} using your preferred app`,
      user: `Join a chat with ${dataPage?.userName} using your preferred app`,
      companyChannel: groupLinkText,
      channel: groupLinkText,
    };

    const linkType = dataPage?.linkType;

    if (!linkType) {
      return defaultText;
    }

    return messages[linkType];
  };

  const titleMessage = async () => {
    const responseChannel = await getChannelMap({
      path: nameInPath,
      userId: user.id,
    });
    const textAlreadyUser = user?.id ? 'Welcome back' : 'Hello';
    const commsTool = providers.includes(user?.commsTool?.provider)
      ? capitalizeFirstLetter(user?.commsTool?.provider)
      : null;
    const defaultText = commsTool
      ? `${textAlreadyUser} ${user.name}, you're already connected to ${commsTool}.`
      : `${textAlreadyUser} ${user.name}, connect your primary communication platform to centralize cross-platform
    conversations with contacts using other chat tools.`;
    const groupLinkText =
      responseChannel.status === 200
        ? `${textAlreadyUser} ${user.name}, you're already a member of ${dataPage?.channelName}. Please go check your ${commsTool}.`
        : `${textAlreadyUser} ${user.name}, click 'Join Channel' to join ${dataPage?.channelName} in your ${commsTool}.`;
    const messages = {
      companyUser: `${textAlreadyUser} ${user.name}, click 'Join Channel' to join a conversation with ${dataPage?.userName} at ${dataPage?.companyName} in your ${commsTool}.`,
      company: `${textAlreadyUser} ${user.name}, click 'Join Channel' to join a conversation with ${dataPage?.companyName} in your ${commsTool}.`,
      user: `${textAlreadyUser} ${user.name}, click 'Join Channel' to join a conversation with ${dataPage?.userName} in your ${commsTool}.`,
      companyChannel: groupLinkText,
      channel: groupLinkText,
    };

    const linkType = dataPage?.linkType;

    if (!linkType) {
      return setTitle(defaultText);
    }

    return setTitle(messages[linkType]);
  };

  useEffect(() => {
    if (user?.id) {
      titleMessage();
    }
  }, [user]);
  
  if (userExist && providers.includes(user?.commsTool?.provider)) {
    return (
      <>
        <ContainerTexts>
          <Title breakLine>{title}</Title>
        </ContainerTexts>
        <BoxUserExistUser />
      </>
    );
  }

  if (userExist && !providers.includes(user?.commsTool?.provider)) {
    return (
      <Container>
        <ConnectChatTool email={emailField} name={user?.name} />
      </Container>
    );
  }

  return (
    <Container>
      <ContainerTexts>
        {dataPage?.photo && (
          <BackgroundImageWhitelabel>
            <img
              id="image_whitelabel"
              src={dataPage?.photo}
              alt={`logo ${dataPage?.userName} `}
              style={{
                background: '#FFF',
                maxWidth: '120px',
                maxHeight: '120px',
              }}
            />
          </BackgroundImageWhitelabel>
        )}
        <Title>
          {pathname === '/' || isGetStarted || !dataPage || !name
            ? 'Connect your chat tool and invite others to choose theirs. A.link handles the bridge.'
            : `Connect with ${name}`}
        </Title>
        {pathname !== '/' || (isGetStarted && <Subtitle>{subtitleMessage()}</Subtitle>)}
      </ContainerTexts>
      <ContainerInputForm
        disable={isGetStarted}
        disabledForm={!verifyEmail && styleInInput}>
        <ContainerInputLabel>
          <label htmlFor="email">Enter your email</label>
          <input
            disabled={isGetStarted}
            value={emailField}
            onChange={(e) => setEmailField(e.target.value)}
            type="email"
            id="email"
          />
        </ContainerInputLabel>
      </ContainerInputForm>
      <BoxCommsTools>
        <LabelCommsTools>Join with your favorite chat tool</LabelCommsTools>
        <ContainerCommsTools>
          {!verifyEmail && styleInInput
            ? commsTools.map((commsTool) => {
                const isDisabled = !dataPage?.supportedProviders?.includes(
                  commsTool.name
                );
                return (
                  <ContainerCommsToolButton
                    styleDisabled={isDisabled || (!verifyEmail && styleInInput)}
                    key={commsTool.title}
                    aria-disabled={isDisabled || (!verifyEmail && styleInInput)}
                    disabled={isDisabled || (!verifyEmail && styleInInput)}
                    onClick={() => selectCommsTools(commsTool)}>
                    <img src={commsTool.logo} alt={commsTool.title} />
                  </ContainerCommsToolButton>
                );
              })
            : commsTools.map((commsTool) => {
                const isDisabled = !dataPage?.supportedProviders?.includes(
                  commsTool.name
                );
                if (isDisabled && dataPage?.supportedProviders) {
                  return null;
                }
                return (
                  <>
                    <ContainerCommsTool
                      styleDisabled={!verifyEmail && styleInInput}
                      key={commsTool.title}
                      role="button"
                      disabled={!verifyEmail && styleInInput}
                      aria-disabled={!verifyEmail && styleInInput}
                      onClick={() => selectCommsTools(commsTool)}>
                      <img src={commsTool.logo} alt={commsTool.title} />
                    </ContainerCommsTool>
                  </>
                );
              })}
        </ContainerCommsTools>
      </BoxCommsTools>
      <FooterNote>
        By proceeding, you agree to our <a href="https://startadam.com/terms/">Terms</a>{' '}
        and <a href="https://startadam.com/privacy/">Privacy Policy.</a>
      </FooterNote>
    </Container>
  );
};

BoxCommsToolsWorkerCompanyAndUser.defaultProps = {
  userEmail: '',
};
