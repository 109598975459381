import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3em;
`;

export const Title = styled.h3`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #1a1e2b;
  max-width: 350px;

  @media (max-width: 410px) {
    width: 85%;
  }

  .name {
    font-style: italic;
  }
`;
