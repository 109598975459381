import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;

  @media (max-width: 610px) {
    width: 90%;
  }
`;

export const Breadcrumbs = styled.div<{ $active: boolean }>`
  width: 12.688rem;
  height: 2.25rem;
  border: solid 1px #2a7de1;
  border-radius: 31.25rem;
  background-color: ${({ $active }) => ($active ? '#c9def7' : 'transparent')};
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-size: 0.813rem;
    font-weight: bold;
    color: ${({ $active }) => ($active ? '#2a7de1' : '#C9DEF7')};
  }
`;
