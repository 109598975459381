import { FC } from 'react';
import {
  iconCheck,
  iconEncryption,
  iconGDPR,
  iconNumberOne,
  iconNumberThree,
  iconNumberTwo,
  iconSoc2Type2,
  iconUptime,
} from 'assets/icons';
import { Checklist, Container, IconGroup, Title, Wrapper } from './styles';

interface IGettingStarted {
  isJoinLink?: boolean;
  isSuccess?: boolean;
}
export const GettingStarted: FC<IGettingStarted> = ({ isJoinLink, isSuccess }) => {
  const gettingStarted = [
    'Make your chat app interoperable',
    'Stop switching apps & missing messages',
    'Keep your phone number private',
  ];
  const safeAndEasy = [
    'You choose how you want to connect',
    'Fully encrypted, GDPR & SOC 2 Type II compliant',
    'Keep your phone number private',
  ];
  const howItWorks = [
    { icon: iconNumberOne, text: 'Connect your preferred tool' },
    { icon: iconNumberTwo, text: 'Receive a new channel/group' },
    {
      icon: iconNumberThree,
      text: 'It’s magic! Start chatting cross-platform with your host',
    },
  ];
  const titleChecklist = isJoinLink ? 'Safe & Easy' : 'Get started for free';
  const checkList = isJoinLink ? safeAndEasy : gettingStarted;
  return (
    <Container>
      {isJoinLink && !isSuccess && (
        <Wrapper>
          <Title>How it works?</Title>
          <div>
            {howItWorks.map((element) => {
              return (
                <Checklist key={element.text}>
                  <img alt="check" src={element.icon} height={20} />
                  <p>{element.text}</p>
                </Checklist>
              );
            })}
          </div>
        </Wrapper>
      )}

      {!isSuccess && (
        <Wrapper>
          <Title>{titleChecklist}</Title>
          <div>
            {checkList.map((element) => {
              return (
                <Checklist key={element}>
                  <img alt="check" src={iconCheck} height={15} />
                  <p>{element}</p>
                </Checklist>
              );
            })}
          </div>
        </Wrapper>
      )}
      <Wrapper>
        <Title>Your data is safe</Title>
        <IconGroup>
          <img src={iconSoc2Type2} alt="soc2-type2" />
          <img src={iconEncryption} alt="encryption" />
          <img src={iconGDPR} alt="gdpr" />
          <img src={iconUptime} alt="uptime-achieved" />
        </IconGroup>
      </Wrapper>
    </Container>
  );
};

GettingStarted.defaultProps = {
  isJoinLink: false,
  isSuccess: false,
};
