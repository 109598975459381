import { FC, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getAdamUser } from 'store/slices/getAdamUser/middleware';
import { setCommsTool } from 'store/slices/commsTool';
import { BoxCommsToolsManager } from 'components/BoxCommsToolsManager';
import { BoardTools } from 'components/BoardTools';
import { selectLogoCommsTool } from 'services/selectCommsTool';

import { Container } from './styles';

export const SignUpManager: FC = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const userEmail = queryParams.get('userEmail');
  const [showSelectBoardTool, setShowSelectBoardTool] = useState(false);
  const { email } = useParams();

  const { user } = useAppSelector((state) => state.adamUser);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAdamUser({ email }));
    dispatch(setCommsTool(selectLogoCommsTool(user?.commsTool?.provider)));
  }, [email, dispatch, user]);

  console.log('SignUpManager');

  return (
    <Container>
      <>
        <BoxCommsToolsManager
          setShowSelectBoardTool={setShowSelectBoardTool}
          showSelectBoardTool={!showSelectBoardTool}
          userEmail={userEmail}
        />
        <BoardTools
          showSelectBoardTool={showSelectBoardTool}
          setShowSelectBoardTool={setShowSelectBoardTool}
        />
      </>
    </Container>
  );
};
