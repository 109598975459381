import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { backgroundPage } from 'assets/images';
import { Footer } from 'components/Footer';
import gtm from 'services/gtm';
import { ContainerLogo } from 'styles/app';
import GlobalStyle, { ImageBackground } from 'styles/global';
import Router from './router';

const App = () => {
  useEffect(() => {
    gtm();
  }, []);

  document.addEventListener('keydown', (e: any) => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  });

  return (
    <>
      <ContainerLogo />
      <GlobalStyle />
      <Router />
      <ImageBackground src={backgroundPage} alt="" />
      <ToastContainer position="top-right" />
      <Footer />
    </>
  );
};

export default App;
