import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { BoxCommsToolsWorkerCompanyAndUser } from 'components/BoxCommsToolsWorkerCompanyAndUser';
import { Breadcrumb } from 'components/Breadcrumb';
import { GettingStarted } from 'components/GettingStarted/GettingStarted';
import { LoadingSpinner } from 'components/BoxSuccess/styles';
import { getWhitelabelPath } from 'services/getWhitelabelPath';
import { getWhitelabel } from 'store/slices/getWhitelabel/middleware';
import { IDataWhitelabel } from 'store/slices/getWhitelabel/types';
import { Container } from './styles';

export const SignUpWorkerCompanyAndUser: FC = () => {
  const { search } = useLocation();
  const { email } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [dataPage, setDataPage] = useState<IDataWhitelabel | null>(null);
  const [isGetStated, setIsGetStarted] = useState(false);
  const [loading, setLoading] = useState(true);

  const queryParams = new URLSearchParams(search);

  const userEmail = queryParams.get('userEmail') || email;

  window.onpopstate = () => {
    location.reload();
  };
  const pathname = location.pathname;
  const nameInPath = pathname === '/' ? pathname : pathname.replace('/', '');

  useEffect(() => {
    const getResponse = async () => {
      if (nameInPath.startsWith('getstarted')) {
        setIsGetStarted(true);
        setLoading(false);
        return;
      }
      const responseData = await getWhitelabelPath(nameInPath);
      dispatch(getWhitelabel(nameInPath));
      if (responseData.status === 204) {
        navigate('/invalid-invite');
        return;
      }
      setLoading(false);
      setDataPage(responseData.data);
    };
    getResponse();
  }, [pathname]);

  console.log('SignUpWorkerCompanyAndUser');

  if (loading) {
    return (
      <Container>
        <LoadingSpinner />
      </Container>
    );
  }

  return (
    <Container>
      {(nameInPath.startsWith('getstarted') || pathname === '/') && (
        <Breadcrumb pages={['Connect tool', 'Share & Chat']} active="Connect tool" />
      )}
      <BoxCommsToolsWorkerCompanyAndUser
        nameInPath={nameInPath}
        pathname={pathname}
        dataPage={dataPage}
        userEmail={userEmail}
        isGetStarted={isGetStated}
      />
      <GettingStarted isJoinLink={pathname !== '/'} />
    </Container>
  );
};
