import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getAdamUser } from 'store/slices/getAdamUser/middleware';
import { BoxSuccess } from 'components/BoxSuccess';
import { Breadcrumb } from 'components/Breadcrumb';
import { GettingStarted } from 'components/GettingStarted';
import { Container, LoadingSpinner } from './styles';

export const SuccessPage: FC = () => {
  const { user } = useAppSelector((state) => state.adamUser);

  const { userReference } = useParams();
  const dispatch = useAppDispatch();

  const [isJoinLink, setIsJoinLink] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getUser = async () => {
      await dispatch(getAdamUser({ email: userReference }));
    };
    getUser();
  }, [userReference, dispatch]);

  useEffect(() => {
    if (user?.userReference) {
      setIsJoinLink(true);
      setLoading(false);
    }
  }, [user]);

  if (loading) {
    return (
      <Container>
        <LoadingSpinner />
      </Container>
    );
  }

  return (
    <Container>
      {!isJoinLink && (
        <Breadcrumb pages={['Connect tool', 'Share & Chat']} active="Share & Chat" />
      )}
      <BoxSuccess user={user} isJoinLink={isJoinLink} />
      <GettingStarted isSuccess />
    </Container>
  );
};
