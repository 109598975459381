import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(148, 189, 239, 0.5);
  border-radius: 10px;
  max-width: 400px;
  width: 100%;
  padding: 2em 0;
  gap: 45px;
  z-index: 20;

  @media (max-width: 410px) {
    width: 95%;
  }
`;

export const ButtonAccess = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  background: #ffffff;
  color: #1a1e2b;
  box-shadow: 0px 10px 25px rgba(148, 189, 239, 0.5);
  border-radius: 5px;
  width: 194px;
  height: 48px;
  cursor: pointer;
  transition: 0.3s;
  z-index: 20;
  &:hover {
    transform: scale(1.05);
  }
`;

export const Info = styled.p`
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #b0b0b0;
`;

export const ContainerLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5em;

  @media (max-width: 360px) {
    & > img {
      width: 90px;
    }
  }
`;

export const CommsTool = styled.div`
  margin: 0;
`;

export const ContainerSpinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
`;

export const ButtonChannel = styled.button`
  background: #ccff00;
  box-shadow: 0px 10px 25px rgba(148, 189, 239, 0.5);
  border-radius: 5px;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #1a1e2b;
  transition: 0.3s;
  cursor: pointer;
  width: 194px;
  height: 48px;
  &:hover {
    transform: scale(1.05);
  }
`;
