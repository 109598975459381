import { FC } from 'react';
import { BoxCommsToolsTest } from 'components/BoxCommsToolTest';
import { GettingStarted } from 'components/GettingStarted';
import { Container } from './styles';

export const SignUpTest: FC = () => {
  console.log('SignUpTest');
  
  return (
    <Container>
      <BoxCommsToolsTest />
      <GettingStarted isJoinLink />
    </Container>
  );
};
