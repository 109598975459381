import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
  background: #e9f1fb;
  box-shadow: 0rem 0.625rem 1.563rem rgba(148, 189, 239, 0.5);
  border-radius: 0.625rem;

  max-width: 28.125rem;
  width: 100%;
  padding: 2em 1em;
  z-index: 20;

  @media (max-width: 38.125rem) {
    width: 90%;
    padding: 2em 1em;
  }
`;

export const Title = styled.h3<{ breakLine?: boolean }>`
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 1.188rem;
  line-height: 1.563rem;
  text-align: center;
  letter-spacing: -0.02em;
  color: #1a1e2b;
  width: 21.875rem;

  ${({ breakLine }) => breakLine && `line-break: auto;`};

  @media (max-width: 38.125rem) {
    width: 60%;
  }
`;

export const ContainerCommsTools = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  max-width: 25rem;
`;

export const ContainerTexts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
`;

export const LabelCommsTools = styled.h4`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: -0.02em;
  display: flex;
  margin-left: 1.625rem;
  margin-bottom: 0.625rem;
  text-align: left !important;
  color: #1a1e2b;
`;

export const BoxCommsTools = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerCommsToolButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  background: #ffffff;
  box-shadow: 0rem 0.625rem 1.563rem rgba(148, 189, 239, 0.5);
  border-radius: 0.313rem;
  width: 10.688rem;
  height: 3rem;
  cursor: pointer;
  transition: 0.3s;
  z-index: 12;

  @media (max-width: 26.813rem) {
    width: 100%;
    height: 3.25rem;
  }

  &:hover {
    transform: scale(1.05);
  }
`;

export const BackgroundImageWhitelabel = styled.div`
  width: 7.5rem;
  height: 7.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 0.313rem;
  align-self: center;
`;
