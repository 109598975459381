import styled from 'styled-components';

export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgb(26, 30, 43, 0.3);
  z-index: 10000;
`;

export const ContainerContentModal = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background: #fff;
  padding: 10px;
  z-index: 100;
  opacity: 1;
  overflow-y: auto;
`;

export const ContainerCloseModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

export const ButtonClose = styled.button`
  background: transparent;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const ContentModal = styled.div<{ $maxWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  ${({ $maxWidth }) => ($maxWidth ? null : 'max-width:80%')}
`;
