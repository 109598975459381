import { endpointsIntegration } from 'config/endpoints';
import { adamApiII } from './api';

export const getChannelMap = async (payload: { path: string; userId: string }) => {
  try {
    const response = await adamApiII.post(endpointsIntegration.checkJoinChannel, payload);
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};
