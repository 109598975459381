import styled from 'styled-components';

export const Container = styled.button<{ showButtons: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f2eff9;
  box-shadow: 0px 10px 25px rgba(195, 179, 229, 0.5);
  border-radius: 10px;
  padding: 1.5em;
  gap: 1em;
  max-width: 550px;
  width: 100%;
  cursor: ${({ showButtons }) => (showButtons ? 'default' : 'pointer')};
  z-index: 20;
  @media (max-width: 610px) {
    width: 90%;
  }
`;

export const ContainerTexts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SelectTool = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #1a1e2b;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerSelectButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const ContainerButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;

  @media (max-width: 410px) {
    flex-direction: column;
  }
`;

export const InfoSelectTool = styled.p`
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #1a1e2b;
`;

export const Button = styled.button<{ disableStyle?: boolean }>`
  background: #ffffff;
  box-shadow: 0px 10px 25px rgba(195, 179, 229, 0.5);
  border-radius: 5px;
  width: 171px;
  height: 48px;
  cursor: ${({ disableStyle }) => (disableStyle ? 'default' : 'pointer')};
  transition: 0.3s;
  opacity: ${({ disableStyle }) => (disableStyle ? '0.5' : '1')};
  &:hover {
    transform: ${({ disableStyle }) => (disableStyle ? 'none' : 'scale(1.05)')};
  }

  @media (max-width: 410px) {
    width: 100%;
  }
`;
