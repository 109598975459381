import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IDataWhitelabel } from './types';

interface IInitialStateWhitelabel {
  whitelabel: IDataWhitelabel;
}

const initialState: IInitialStateWhitelabel = {
  whitelabel: null,
};

const dataWhitelabelSlice = createSlice({
  name: 'whitelabel',
  initialState,
  reducers: {
    setWhitelabel: (state, action: PayloadAction<IDataWhitelabel>) => {
      state.whitelabel = action.payload;
    },
  },
});

export const { setWhitelabel } = dataWhitelabelSlice.actions;

export default dataWhitelabelSlice.reducer;
