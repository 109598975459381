import { FC, useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import { validateEmail } from 'services/validateEmail';
import { selectLogoCommsToolForBack } from 'services/selectCommsTool';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setCommsTool } from 'store/slices/commsTool';
import { getAdamUser } from 'store/slices/getAdamUser/middleware';
import { commsTools } from 'config/listCommsTools';
import { TitleUserExist } from 'pages/SignUpManager/styles';

import { BoxUserExistUser } from 'components/BoxUserExistUser';
import {
  Container,
  ContainerCommsTool,
  ContainerCommsTools,
  ContainerTexts,
  Title,
  ContainerInputForm,
  ContainerInputLabel,
} from './styles';

interface IBoxCommsToolsProps {
  showSelectBoardTool: boolean;
  setShowSelectBoardTool: (showBoard: boolean) => void;
  userEmail?: string;
}

const ADAM_II_URL = process.env.REACT_APP_ADAM_II_URL;

export const BoxCommsToolsManager: FC<IBoxCommsToolsProps> = ({
  showSelectBoardTool,
  setShowSelectBoardTool,
  userEmail,
}) => {
  const [styleInInput, setStyleInInput] = useState(false);
  const [emailExist, setEmailExist] = useState<string>();
  const [verifyEmail, setVeirfyEmail] = useState(true);
  const [emailField, setEmailField] = useState('');

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { email } = useParams();
  const { pathname } = useLocation();

  const { user } = useAppSelector((state) => state.adamUser);
  const { commsTool: commsToolState } = useAppSelector((state) => state.commsTool);

  window.onpopstate = () => location.reload();

  useEffect(() => {
    const checkEmail = async () => {
      if (validateEmail(emailField)) {
        const res = await dispatch(getAdamUser({ email: emailField }));
        if (res?.payload?.userReference) {
          setEmailExist(res?.payload?.userReference);
        }
        return setVeirfyEmail(true);
      }
      return setVeirfyEmail(false);
    };
    checkEmail();
  }, [emailField, dispatch]);

  useEffect(() => {
    if (email) {
      const result = validateEmail(email);
      if (!result) {
        navigate('/invalid-email');
      }
    }
  }, [email, navigate]);

  useEffect(() => {
    const verifyEamilExist = () => {
      if (email) {
        dispatch(getAdamUser({ email }));
        if (user?.userReference) {
          setEmailExist(user.userReference);
        }
      }
    };
    verifyEamilExist();
  }, [email, user, dispatch]);

  const redirect = (page = 'success') => {
    return navigate(`/${page}`);
  };

  const selectCommsTool = (commsTool) => {
    setStyleInInput(true);

    if (commsTool.title === 'MS Teams') {
      redirect(`activate?url=${location.href}&manager=true`);
      return;
    }

    if (validateEmail(email || emailField)) {
      const nameInPath = pathname.replace('/', '').split('/')[0].toLowerCase();

      const path = pathname.toLowerCase().includes(nameInPath)
        ? ''
        : pathname.replace('/', '');
      const state = window.btoa(
        JSON.stringify({
          path,
          provider: selectLogoCommsToolForBack(commsTool?.title),
          userReference: emailField || email,
        })
      );

      dispatch(setCommsTool(commsTool?.title));

      location.href = `${ADAM_II_URL}/chatIntegration?state=${state}`;
    }
  };

  useEffect(() => {
    setEmailField(userEmail);
  }, [userEmail]);

  return (
    <>
      {!emailExist ? (
        <Container
          button={showSelectBoardTool}
          onClick={() => setShowSelectBoardTool(false)}>
          <ContainerTexts>
            <Title>
              {!emailExist ? 'Connect' : 'Update'} your chat tool to enable cross-platform
              communication
            </Title>
          </ContainerTexts>
          {showSelectBoardTool && (
            <>
              {pathname === '/' && !user?.userReference && (
                <ContainerInputForm disabledForm={!verifyEmail && styleInInput}>
                  <ContainerInputLabel>
                    <label htmlFor="email">Enter your email</label>
                    <input
                      value={emailField}
                      onChange={(e) => setEmailField(e.target.value)}
                      type="email"
                      id="email"
                    />
                  </ContainerInputLabel>
                </ContainerInputForm>
              )}
              <ContainerCommsTools>
                {commsTools.map((commsTool) => {
                  return (
                    <ContainerCommsTool
                      disabled={
                        commsTool?.disabled ||
                        (!verifyEmail && pathname === '/' && styleInInput)
                      }
                      styleDisabled={
                        commsTool?.disabled ||
                        (!verifyEmail && pathname === '/' && styleInInput)
                      }
                      key={commsTool.title}
                      onClick={() => {
                        selectCommsTool(commsTool);
                      }}>
                      <img src={commsTool.logo} alt={commsTool.title} />
                    </ContainerCommsTool>
                  );
                })}
              </ContainerCommsTools>
            </>
          )}
        </Container>
      ) : (
        <>
          <TitleUserExist>
            Hello {user.name}, you&apos;re already connected to {commsToolState}.
          </TitleUserExist>
          <BoxUserExistUser />
        </>
      )}
    </>
  );
};

BoxCommsToolsManager.defaultProps = {
  userEmail: '',
};
