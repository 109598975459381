import { createAsyncThunk } from '@reduxjs/toolkit';

import { endpointsIntegration } from 'config/endpoints';
import { adamApiII } from 'services/api';

import { setAdamUser } from '.';
import { IAdamUser } from './types';

export const getAdamUser = createAsyncThunk(
  'getUser/get',
  async ({ whitelabelId, email }: { whitelabelId?: string; email: string }, thunkAPI) => {
    try {
      const response = await adamApiII.get<IAdamUser>(
        endpointsIntegration.getUser(whitelabelId, email)
      );
      thunkAPI.dispatch(setAdamUser(response.data));
      return response.data;
    } catch (error) {
      console.log(error);
      thunkAPI.rejectWithValue(error);
      return error;
    }
  }
);
