import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
  background: #e9f1fb;
  box-shadow: 0px 10px 25px rgba(148, 189, 239, 0.5);
  border-radius: 10px;

  max-width: 550px;
  width: 100%;
  padding: 2em 1em;
  z-index: 20;

  @media (max-width: 610px) {
    width: 90%;
    padding: 2em 1em;
  }
`;

export const Title = styled.h3<{ breakLine?: boolean }>`
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 19px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #1a1e2b;
  width: 80%;

  ${({ breakLine }) => breakLine && `line-break: auto;`};

  @media (max-width: 410px) {
    width: 60%;
  }
`;

export const Subtitle = styled.p`
  width: 350px;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #1a1e2b;
`;

export const ContainerCommsTools = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  max-width: 400px;
`;

export const ContainerCommsTool = styled.button<{ styleDisabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  background: #ffffff;
  box-shadow: 0px 10px 25px rgba(148, 189, 239, 0.5);
  border-radius: 5px;
  width: 171px;
  height: 48px;
  cursor: ${({ styleDisabled }) => (styleDisabled ? 'default' : 'pointer')};
  transition: 0.3s;
  z-index: 12;
  opacity: ${({ styleDisabled }) => (styleDisabled ? '0.3' : '1')};

  @media (max-width: 429px) {
    width: 100%;
    height: 52px;
  }

  &:hover {
    transform: ${({ styleDisabled }) => (styleDisabled ? 'none' : 'scale(1.05)')};
  }
`;

export const ContainerCommsToolDisabled = styled(ContainerCommsTool)`
  opacity: 0.5;
  z-index: 10;

  .project-card {
    margin: -119px 0 0 -24px;
    height: 150px;
    width: 150px;
    position: absolute;
    z-index: 11;
  }

  .achievement-label {
    opacity: 1 !important;
    display: grid;
    z-index: 11;
    position: absolute;
    width: 217px;
    padding: 0.1rem 0;
    box-sizing: border-box;
    background-color: #ccff00;
    transform: rotateZ(-45deg);
    top: 0;
    margin-top: 50px;
    left: 0;
    transform-origin: 150px 150px;
    text-align: justify;
    text-align-last: justify;
    clip-path: polygon(90px 0, calc(100% - 90px) 0, 100% 90px, 0 90px);
  }

  p {
    font-weight: 700;
    color: #1a1e2b;
    font-size: 12px;
    z-index: 14;

    margin: 0;
  }

  div.achievement-label i {
    float: left;
    height: 100%;
    width: 90px;
    shape-outside: polygon(0 0, 90px 0%, 0 90px, 0% 90px);
  }

  div.achievement-label i + i {
    float: right;
    shape-outside: polygon(0 0, 90px 0, 90px 90px, 90px 90px);
  }
`;

export const ContainerTexts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
`;

export const Security = styled.p`
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #b0b0b0;
`;

export const ContainerInputForm = styled.form<{
  disabledForm: boolean;
  disable?: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 0.3em;
  width: 100%;
  justify-content: center;
  align-items: center;
  label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: #1a1e2b;
  }

  input {
    background: ${({ disable }) => (disable ? 'rgba(243, 243, 243, 0.8667)' : '#ffffff')};
    width: 350px;
    height: 48px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    padding: 0 0 0 10px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: ${({ disable }) => (disable ? '#B0B0B0' : '#1a1e2b')};
    border: solid 1px ${({ disabledForm }) => (disabledForm ? '#ff0000' : 'none')};
    outline: none;
    @media (max-width: 420px) {
      width: 100%;
    }
  }
`;

export const ContainerInputLabel = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 430px) {
    width: 100%;
  }
`;

export const LabelCommsTools = styled.h4`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  display: flex;
  margin-left: 26px;
  margin-bottom: 10px;
  text-align: left !important;
  color: #1a1e2b;
`;

export const BoxCommsTools = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerCommsToolButton = styled.button<{ styleDisabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  background: #ffffff;
  box-shadow: 0px 10px 25px rgba(148, 189, 239, 0.5);
  border-radius: 5px;
  width: 171px;
  height: 48px;
  cursor: ${({ styleDisabled }) => (styleDisabled ? 'default' : 'pointer')};
  transition: 0.3s;
  z-index: 12;

  @media (max-width: 429px) {
    width: 100%;
    height: 52px;
  }

  &:hover {
    transform: ${({ styleDisabled }) => (styleDisabled ? 0 : 'scale(1.05)')};
  }
`;

export const BackgroundImageWhitelabel = styled.div`
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 5px;
  align-self: center;
`;

export const FooterNote = styled.p<{ $footer?: boolean }>`
  font-size: 0.625rem;
  color: #c6c6c6;
  margin: ${({ $footer }) =>
    $footer ? '0 0.875rem 0.875rem 3.125rem' : '1.5625rem 0 0 0'};

  a {
    text-decoration: underline;
    color: #c6c6c6;
    padding-right: 0.1875rem;
  }
`;
