import styled from 'styled-components';

export const ContainerLogo = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: 2.2em 150px;

  @media (max-width: 800px) {
    justify-content: center;
  }
`;
