import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  h5 {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 30px;
    line-height: 35px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #1a1e2b;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(148, 189, 239, 0.5);
  box-shadow: 0px 10px 25px rgba(148, 189, 239, 0.5);
  border-radius: 10px;
  max-width: 550px;
  width: 100%;
  padding: 18px;
  gap: 30px;
  transition: 0.3s;
  z-index: 20;

  @media (max-width: 410px) {
    width: 95%;
    padding: 0.5rem;
  }
`;

export const Subtitle = styled.p`
  font-family: 'Montserrat';
  font-size: 18px;
  line-height: 35px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #1a1e2b;
`;

export const Text = styled(Subtitle)`
  font-size: 14px;
  font-weight: 600;
`;

export const ContainerClipboard = styled.button`
  background: rgba(148, 189, 239, 0.5);
  display: flex;
  align-items: center;
  border-radius: 5px;
  justify-content: center;
  padding: 6px 12px;
  cursor: pointer;
  max-width: 480px;
  width: 100%;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #0000ee;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;

  &:hover {
    transform: scale(1.05);
  }
`;
